<template>

    <div class="flex flex-col p-4">
        <p @click="showAnimations=!showAnimations" class="inline-block cursor-pointer text-xl text-brand" style="width: fit-content;">Анимация игры
            <font-awesome-icon v-if="showAnimations"
                               :icon="['fal', 'angle-up']">
            </font-awesome-icon>
            <font-awesome-icon v-if="!showAnimations"
                               :icon="['fal', 'angle-down']"></font-awesome-icon>
        </p>

        <div v-if="animations" v-show="showAnimations">
          <spinner v-if="isLoading"></spinner>
          <SortableList v-if="!isLoading" v-model="animations" @input="saveAnimationSort" class="outline-none">

            <div class="contact-list" slot-scope="{items: animations}">

                <animation v-for="(animation, key) in animations"
                          @close_popups="close_popups"
                         :idx="key"
                         :key="animation.id"
                         :template_id="template_id"
                         :tourMedia="tourMedia"
                         :animation="animation"
                         :rules="rules"
                         @itemsUpdated="updateAnimation(key, $event)"
                         @deleteTour="deleteTour($event)"
                         ref="animations">
                </animation>

            </div>
          </SortableList>
          <OnClickOutside :do="close">
            <div class="numbers w-56 pr-3 relative">
                <button type="button"
                        class="no-underline circle bg-brand-red text-white pb-2 outline-none"
                        ref="button"
                        @click.prevent="openPopup">+
                </button>
                <!-- @click.prevent="addCustomAnimation()" -->
                <div class="set-new-animation bg-white border border-brand-dark py-3 mb-4 ml-2 rounded"
                v-show="isOpen" ref="animation_setter">
                  <p @click.prevent="addCustomAnimation()"  class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Видео / Анимация</p>
                  <p @click.prevent="addAdvAnimation" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Афиша</p>
                  <p @click.prevent="addResult()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Таблица результатов</p>
                  <p @click.prevent="addSingleResult('result-last')" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Последнее место</p>
                  <p @click.prevent="addSingleResult('result-third')" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">3 место</p>
                  <p @click.prevent="addSingleResult('result-second')" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">2 место</p>
                  <p @click.prevent="addSingleResult('result-first')" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">1 место</p>
                  <p @click.prevent="addRandom()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Рандом</p>
                  <p @click.prevent="addTeamRepresentation()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Презентация команд</p>
                  <p @click.prevent="addTourSelection()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Выбор тура</p>
                  <p @click.prevent="addTourSelectionResult()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Результаты выбора тура</p>
                  <p @click.prevent="addRecordReviewLink()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Оценка игры (только для офлайн игр)</p>
                  <p @click.prevent="addTwinSlide()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Слайд запуска виджета Twin</p>
                  <p @click.prevent="addTwinTimerSlide()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Слайд таймера Twin</p>
                  <p @click.prevent="addMultyplayerResultsSlide()" class="font-semibold hover:text-white hover:bg-brand-dark cursor-pointer px-2 py-1">Слайд результатов мультиплеера</p>
                </div>
            </div>
          </OnClickOutside>
        </div>
    </div>

</template>
<script>
import debounce from 'lodash/debounce'
import axios from 'axios'
import Animation from './animation'
import SortableList from './SortableList'
import Popper from 'popper.js'
import OnClickOutside from '@/components/OnClickOutside'
import Spinner from '@/components/spinner'

const uuidv4 = require('uuid/v4')

export default {
  name: 'Animations',
  components: { Animation, SortableList, OnClickOutside, Spinner },
  props: ['tour_count', 'template_id', 'start_from_zero', 'gameType', 'rules'],
  data () {
    return {
      animations: [],
      tourAnimations: [],
      showAnimations: true,
      isOpen: false,
      isLoading: false,
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      axios.get(`/api/templates/${this.template_id}/animations`)
        .then(({ data }) => {
          this.animations = data.body ? data.body : []
          this.tourMedia = data.media
          this.tourAnimations = this.animations.filter(function (obj) {
            return obj.type === 'tour'
          })
          this.checkGameMainAnimation()
          if (this.tourAnimations && this.tour_count) {
            this.fillByTour()
          }
          this.isLoading = false
        })
    },
    addAnimation (displayValue, type, items, id = null) {
      if (!id) {
        id = uuidv4()
      }
      this.animations.push({
        displayValue: displayValue,
        id: type === 'tour' ? displayValue : id,
        type: type,
        items: items,
      })
      this.save(this.animations)
    },
    updateAnimation (key, params) {
      this.animations[key].items = params
      if (this.animations[key].type !== 'result' && this.animations[key].type !== 'advertisement' && (!this.animations[key].items || this.animations[key].items.length === 0)) {
        this.dropAnimation(key)
      }
      this.save(this.animations)
    },
    dropAnimation (id) {
      // console.log(id);
      // let save = JSON.parse(JSON.stringify(this.animations));
      // console.log(save);
      // save.splice(id, 1);
      // console.log(id,this.animations, save);
      // this.animations = this.animations.filter(function (obj) {
      //   return obj.id !== id
      // })
      this.animations.splice(id, 1)
      this.save(this.animations)
    },
    save: debounce(function (animations) {
      axios.patch(`/api/templates/${this.template_id}/animations`, { 'animations': animations }).then(() => {
        this.fetchData()
      })
    }, 1000),
    addCustomAnimation () {
      this.addAnimation('В', 'custom', [{ 'name': '', 'collection_name': uuidv4() }])
      this.close()
    },
    addAdvAnimation () {
      this.addAnimation('Р', 'advertisement', [], 'advertisement')
      this.close()
    },
    addResult () {
      let testArr = this.animations.filter((item) => {
        return typeof item.id === 'number' ? item.id : false
      })
      this.addAnimation('Р', 'result', [], `result-${testArr.length}`)
      this.close()
    },
    addSingleResult (type) {
      let testArr = this.animations.filter((item) => {
        return typeof item.id === 'number' ? item.id : false
      })
      let displayTypes = {
        'result-first': { 'name': 'Первое место', 'displayValue': 'T1' },
        'result-second': { 'name': 'Второе место', 'displayValue': 'T2' },
        'result-third': { 'name': 'Третье место', 'displayValue': 'T3' },
        'result-last': { 'name': 'Последнее место', 'displayValue': 'ТП' },
      }
      this.addAnimation(displayTypes[type].displayValue, type, [{ 'name': displayTypes[type].name, 'collection_name': uuidv4() }], `single-${type}-${testArr.length}`)
      this.close()
    },
    addRandom () {
      let testArr = this.animations.filter((item) => {
        return typeof item.id === 'number' ? item.id : false
      })
      this.addAnimation('РМ', 'random', [], `random-${testArr.length}`)
      this.close()
    },
    addTeamRepresentation () {
      this.addAnimation('ПК', 'team-representation', [{ 'name': 'Презентация команд', 'collection_name': uuidv4() }])
      this.close()
    },
    addTourSelection () {
      this.addAnimation('ВТ', 'tour-selection', [{ 'name': 'Выбор тура', 'collection_name': uuidv4() }])
      this.close()
    },
    addRecordReviewLink () {
      this.addAnimation('ОИ', 'record-review-link', [{ 'name': 'Оценка игры', 'collection_name': uuidv4() }])
      this.close()
    },
    addTourSelectionResult () {
      this.addAnimation('РВ', 'tour-selection-result', [{ 'name': 'Результаты выбора тура', 'collection_name': uuidv4() }])
      this.close()
    },
    addTwinSlide () {
      this.addAnimation('ТС', 'twin', [{ 'name': 'Слайд запуска виджета Twin', 'collection_name': uuidv4() }])
      this.close()
    },
    addTwinTimerSlide () {
      this.addAnimation('ТТ', 'twin-timer', [{ 'name': 'Слайд таймера Twin', 'collection_name': uuidv4() }])
      this.close()
    },
    addMultyplayerResultsSlide () {
      this.addAnimation('МР', 'multyplayer-results', [{ 'name': 'Слайд результатов для мультиплеера', 'collection_name': uuidv4() }])
      this.close()
    },
    fillByTour () {
      if (this.tourAnimations.length < this.tour_count) {
        this.addTourAnimation(this.tourAnimations.length, this.tour_count)
      } else if (this.tourAnimations.length > this.tour_count) {
        this.dropExcessTourAnimation(this.tourAnimations.length, this.tour_count)
      }
    },
    dropExcessTourAnimation (actualNumber, tourNumber) {
      for (let i = actualNumber; i > tourNumber; i--) {
        let idx = this.animations.findIndex((item) => item.id === i && item.type === 'tour')
        if (this.gameType === 'MozgoParty') {
          this.dropAnimation(idx + 1)
        }
        this.dropAnimation(idx)
      }
    },
    addTourAnimation (actualNumber, tourNumber) {
      let items = []
      for (let i = actualNumber; i < tourNumber; i++) {
        let j = this.start_from_zero ? i : i + 1;
        items = [
          {
            'name': 'Заставка тура начало',
            'collection_name': `${j}_question_before`,
          },
          {
            'name': 'Заставка тура конец',
            'collection_name': `${j}_question_after`,
          },
          {
            'name': 'Правила',
            'collection_name': `${j}_rules`,
          },
          {
            'name': 'Заставка повтора начало',
            'collection_name': `${j}_repeat_before`,
          },
          {
            'name': 'Заставка повтора конец',
            'collection_name': `${j}_repeat_after`,
          },
          {
            'name': 'Заставка ответа начало',
            'collection_name': `${j}_answer_before`,
          },
          {
            'name': 'Заставка ответа конец',
            'collection_name': `${j}_answer_after`,
          },
        ]
        if (this.gameType === 'MozgoParty') {
          items = [
            {
              'name': 'Заставка тура начало',
              'collection_name': `${j}_question_before`,
            },
            {
              'name': 'Правила',
              'collection_name': `${j}_rules`,
            },
            {
              'name': 'Заставка повтора начало',
              'collection_name': `${j}_repeat_before`,
            },
            {
              'name': 'Заставка ответа начало',
              'collection_name': `${j}_answer_before`,
            },
          ]
          if (this.animations[this.animations.length - 1].type === 'tour') this.addCustomAnimation()
          this.addAnimation(j, 'tour', items)
          this.addCustomAnimation()
          return
        }

        this.addAnimation(j, 'tour', items)
      }
    },
    checkGameMainAnimation () {
      let gameMainAnimation = this.animations.filter(function (obj) {
        return obj.id === 'game-main'
      })[0]

      if (!gameMainAnimation) {
        let items = [
          {
            'name': 'Заставка игры',
            'collection_name': uuidv4(),
          },
          {
            'name': 'Заставка повтора',
            'collection_name': uuidv4(),
          },
          {
            'name': 'Заставка ответов',
            'collection_name': uuidv4(),
          },
        ]
        this.addAnimation('В', 'custom', items, 'game-main')
      }
    },
    saveAnimationSort (e) {
      this.save(e)
    },
    setDisplayValue (startingNumber) {
      let idx = 0 + startingNumber
      this.animations.forEach((item) => {
        if (item.type === 'tour') {
          item.displayValue = idx
          item.id = idx
          item.items.forEach((subItem) => {
            let strArr = subItem.collection_name.split('_')
            subItem.collection_name = [idx, ...strArr.splice(1)].join('_')
          })
          idx++
        }
      })
      this.save(this.animations)
    },
    deleteTour (e) {
      this.animations.splice(e.idx, 1)
      this.save(this.animations)
    },
    deleteAll () {
      this.animations = []
      this.save(this.animations)
    },
    openPopup () {
      if (this.isOpen) {
        return
      }
      this.isOpen = true
      this.$nextTick(() => {
        this.setupPopper()
      })
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.animation_setter, {
          placement: 'right',
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    close_popups () {
      this.$refs.animations.forEach((item) => item.close())
    },
  },
  watch: {
    start_from_zero () {
      this.isLoading = true
      if (this.start_from_zero) {
        this.setDisplayValue(0)
      } else {
        this.setDisplayValue(1)
      }
    },
    tour_count (n, o) {
      if (n !== o) this.fetchData()
    },
    showAnimations (newVal) {
      this.$emit('showAnimationsVal', newVal)
    },
  },
  beforeDestroy () {
    if (this.popper) {
      this.popper.destroy()
    }
  },
}
</script>
<style scoped>
    @tailwind utilities;

    .numbers .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        font-size: 25px;

    }

    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
    .set-new-animation {
      position: absolute;
      z-index: 50;
    }
</style>
